import { usePage } from "@inertiajs/react";

import { AssetClass, Feature } from "@/types";
import { TBreadcrumb } from "@/types/breadcrumb-types";

export type NavItem = {
  id: string;
  label: string;
  type: "workspace" | "link" | "header" | "divider" | "logout" | "slideout";
  component: string | null;
  class: string | null;
  icon: string | null;
  link: string | null;
  new_window: boolean;
  visible: boolean;
  submenu: NavItem[];
  badge?: number;
};

const translationKeys = [
  "audit",
  "asset",
  "note",
  "safetypage",
  "no_audit_conducted",
  "risk_not_available",
  "organisation",
  "site",
  "building",
  "level",
  "record",
  "create_asset",
  "create_record",
  "filter",
  "display",
  "search",
  "order_by",
  "ordering",
  "per_page",
  "none",
  "select",
] as const;

type Translations = {
  [k in (typeof translationKeys)[number]]: string;
};

export type PageData = {
  breadcrumbs: TBreadcrumb[];
  showGlobalBreadcrumbs?: boolean;
  environment: string;
  errors: Record<string, unknown>;
  navItems: {
    mainNavigation: NavItem[];
    settingsDropdown: NavItem[];
  };
  company: {
    companyName: string;
    companySubdomain: string;
    companyUuid: string;
  };
  auth: {
    companies: Company[];
    user: {
      uuid: string;
      user_id: number;
      old_user_id: number;
      firstName: string;
      lastName: string;
      fullName: string;
      initials: string;
      email: string;
      intercom_uuid: string;
      intercomHash: string;
      permissions: string[];
      isSuperAdmin: boolean;
      posthog_id: string;
    };
    session_expires_at: string;
    session_lifetime: number;
  };
  platform: {
    version: string;
    translations: Translations;
    primaryAssetLevel: string;
    enabledAssetLevels: AssetClass[];
  };
  features: Feature[];
  module_settings: {
    confirmed_in_register: "0" | "1" | "2";
    register_versioning: "0" | "1";
  };
};

type Company = {
  uuid: string;
  companyname: string;
  shortname: string;
  domain: string;
};

export const usePageData = <T>() => {
  const pageData = usePage().props as PageData & T;
  // console.info({ pageData });
  // console.info(pageData.platform.translations);
  return pageData;
};
